<template>
  <header class="nav-header" :class="isScroll?'scroll':''">
    <div class="container">
      <div class="logo">
        <img style="width:120px" src="@/assets/img/layout/logo.png" alt="">
      </div>
      <ul class="nav-list" :class="getName=='aboutUs'?'about-us-nav':''">
        <li v-for="(item,index) in navList" :key="index" @click="handleClick(item.name)" >
          <span>{{ item.label }}</span>
        </li>
      </ul>
    </div>
   
  </header>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const navList = [
  {
    label: '首页',
    path: '/home',
    name: 'home'
  },
  {
    label: '跨境电商',
    path: '/kjds',
    name: 'kjds'
  }, {
    label: '社交媒体',
    path: '/sjmt',
    name: 'sjmt'
  }, {
    label: '海外问卷',
    path: '/hwwj',
    name: 'hwwj'
  }, {
    label: '海外营销',
    path: '/hwyx',
    name: 'hwyx'
  }, {
    label: '关于我们',
    path: '/aboutUs',
    name: 'aboutUs'
  }]
function handleClick (name) {
  router.push({ name: name })
}

const route = useRoute()
const isScroll = ref(false)
const getName = computed(() => {
  return route.name
})
document.onscroll = function () {
  if (document.documentElement.scrollTop > 70) {
    isScroll.value = true
  } else {
    isScroll.value = false
  }
}

onMounted(() => {
  if (document.documentElement.scrollTop > 70) {
    isScroll.value = true
  } else {
    isScroll.value = false
  }
})
</script>
<style lang="scss" scoped>

.nav-header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 40px;
  height: 70px;
  z-index: 2024;
  .container{
    height: 100%;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    .logo{
      gap: 0 80px;
    }
    .nav-list{
      display: flex;
      align-items: center;
      gap: 40px;
      justify-content: flex-start;
      color: #333;
        li{
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-size: 14px;
          display: flex;
          align-items: center;
          span{
            cursor: pointer;
            &:hover{
              color: #2b53e5;
            }
          }
          
        }
      }
      .about-us-nav{
        color: white;
        li{
          span{
            &:hover{
            color: white;
            font-weight: 700;
            }
          }
        }
      }
  }
}
.scroll{
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(48, 83, 228, .05);
  .container{
    .about-us-nav{
    color: black;
    li{
      span{
        &:hover{
        color: black;
        font-weight: 700;
        }
      }
    }
  }
  }
  
}
</style>
