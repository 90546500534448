<template>
  <footer class="layout-footer">
    <div class="footer-content">
      <div class="text-center our-channel">我们的官方频道</div>
      <div class="channel">
        <div v-for="item in ourchannelOptions" :key="item.icon">
          <div class="channel-item"  >
            <img :src="require(`@/assets/img/layout/${item.icon}`)" alt="">
          </div>
          <div class="text-center">{{ item.label }}</div>
        </div>

      </div>
      <div class="">
        <div><img class="footer-logo" src="@/assets/img/layout/footer-logo.png" alt=""></div>
        <div class="text-center">© 版权所有 深圳市勤和天下网络技术有限公司</div>
      </div>
    </div>
  </footer>
</template>

<script  setup>
const ourchannelOptions = [
  { label: '公众号', icon: '1.png' },
  { label: '哔哩哔哩', icon: '2.png' },
  { label: '百家号', icon: '3.png' },
  { label: '搜狐号', icon: '4.png' },
  { label: '小红书', icon: '5.png' },
  { label: '抖音号', icon: '6.png' }

]
</script>
<style lang="scss">
.layout-footer{
  height: 560px;
  color: white;
  background:url(@/assets/img/layout/footer-bg.png)  #08050c no-repeat 0 130px;
  background-size: 100% 348px;
  padding-top: 60px;
  .footer-content{
    width: 920px;
    margin: 0 auto;
    .our-channel{
     
      font-size: 36px;
    }
    .channel {
      align-items: center;
      background: rgba(22, 24, 27, .8);
      border: 1px solid hsla(0, 0%, 100%, .1);
      border-radius: 20px 20px 20px 20px;
      color: hsla(0, 0%, 100%, .8);
      display: flex;
      font-size: 16px;
      gap: 80px;
      height: 166px;
      justify-content: center;
      margin: 30px auto 96px;
      padding-top: 2px;
      .channel-item{
        align-items: center;
        background: linear-gradient(90deg, #4a72e7, #2b72ce 97%);
        border-radius: 12px 12px 12px 12px;
        display: flex;
        height: 64px;
        justify-content: center;
        margin-bottom: 10px;
        width: 64px;
        img{
          height: 32px;
          width: 32px;
        }
      }
    }
    .footer-logo{
      height: 45px;
      margin-bottom: 15px;
      width: 156px;
    }
  }
}
</style>
