<template>
  <div class="sidebar">
    <div class="content">
        <div class="item">
          <img src="@/assets/img/home/tel.png" alt="">
          <p>电话咨询</p>
          <div class="bubble" style="color:#05f;;">联系电话：18124747864(微信同号)</div>
        </div>
      <div class="item">
        <img src="@/assets/img/home/wechat.png" alt="">
        <p>微信咨询</p>
        <div class="bubble" style="padding: 15px;color:black">
          <img src="@/assets/img/common/qrcode.png" alt="">
          <div class="mt-2">扫微信客服咨询产品</div>
        </div>
      </div>
    </div>
    <div class="go-top" v-show="showTop" @click="handleScroll" >
      <img  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAA4CAYAAABHRFAgAAAAAXNSR0IArs4c6QAAAjNJREFUaEPt269LBEEUB/DvFwwGi03BrsFoMBgMFkFBwWIwmQWDoP+AySRiMwoWQYNgEougoF2j0WgwGIQnc4zij9ubnbczs7fLTL15O/vZfW+O2dklEjURmQZwAGAKwAOADZJ3KYZnikFEZAbAJYChH+O9AZgneRP7HKIjC4BfriTQqEgHMBk0GrIkMAk0CtITGB0aHKkERoUGRYrIHICzP7Oo7+QZfDIKhhSRFQAnAAZ8VV36B4UGQQYGBk/dyshIwKDQSsjIwJ/QNZLn2jJQIxMBv1wfAFZJnmqgKmRiYGWoN7ImYCWoF7JmoBpaGtknQBW0FLLPgN5QJ7JPgV7Qnsg+B5aGFiIbAiwF7YpsGNAJ/YdsKLAn9Bey4cBC6DeyJcCu0A6yZcB/UIrIgn1kEWJFr1kkxIwxq5dlg3wEMBFzpJqP/WSQUvNJRB/eIG8BmM2YtrYrg5y0mzFjLVQ+dzaV7Ow6CGAWwIgHdAfAuEf/EF3NVt8uAPPI0tXezRYhyQ/nKqToSCJybS+Ma7CQvw+TfPU9YKOQJFXnqwqyKZ78TmZkjxzOd7JXgdcx8eR0zenq+6dj++d0VV44V1iuyVyTrhwp+D3XpPLCucJyTeaadOVIrsnO04RkLddkS2ryheSoJm2qrCePAKxrBlXGXJE0Lyh6typI88L8vfeI+oBFkheacDXSPufZArCnGdgzZp/kpmfMd/dKSAtdArBtP4UIuWlkNmvMpxWHJI+1QBP3CWzKJT+IdnEXAAAAAElFTkSuQmCC" alt="" srcset="" />
    </div>
   
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
const showTop = ref(false)
document.body.onscroll = function () {
  if (document.documentElement.scrollTop > 200) {
    showTop.value = true
  } else {
    showTop.value = false
  }
}
onMounted(() => {
  if (document.documentElement.scrollTop > 200) {
    showTop.value = true
  } else {
    showTop.value = false
  }
})
function handleScroll () {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // 可选，平滑滚动效果
  });
}
</script>

<style lang="scss" scoped>
.bubble {
  position: absolute;
  background-color: #fff;
  padding:  10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  position: absolute;
  right: calc(100% + 10px);
  top: 50%;
  width: auto;
  font-size: 16px;
  transform: translateY(-50%);
  opacity: 0;
  min-width: 200px;
  transition: opacity .3s;
}

.sidebar{
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  .content{
    background: #05f;
    border-radius: 16px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    color: #fff;
    width: 80px;
    .item{
      cursor: pointer;
      padding-bottom: 20px;
      padding-top: 20px;
      position: relative;
      text-align: center;
      &:hover{
      .bubble{
        opacity: 1;
      }
      }
      &:after {
        content: "";
        border-bottom: 1px dashed #fff;
        bottom: 0;
        content: "";
        left: 12px;
        position: absolute;
        width: 56px;
    }
    &:last-child{
      &::after{
      opacity: 0;

      }
    }
      &>img{
        margin: 0 auto;
        width: 24px;
      }
      p{
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-top: 10px;
      }
    }
  }
  .go-top{
    background: #05f;
    border-radius: 16px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    cursor: pointer;
    height: 80px;
    margin-top: 20px;
    opacity: 1;
    padding-top: 28px;
    width: 80px;
    opacity: 1;
    transition: opacity .3s;
    img{
      display: block;
    margin: 0 auto;
    width: 24px;
    }
  }
}
</style>
