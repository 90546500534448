import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/Layout/Layout.vue'

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index')
      },
      {
        path: '/hwwj',
        name: 'hwwj',
        component: () => import('@/views/hwwj/index')
      }, {
        path: '/hwyx',
        name: 'hwyx',
        component: () => import('@/views/hwyx/index')
      },
      {
        path: '/kjds',
        name: 'kjds',
        component: () => import('@/views/kjds/index')
      },      
      {
        path: '/sjmt',
        name: 'sjmt',
        component: () => import('@/views/sjmt/index')
      },      
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/aboutUs/index')
      } 
    ]
  }, {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views//error/404.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
